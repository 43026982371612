<template>
  <div>
    <div class="video-container" :class="isMobile && 'mobile'">
      <video
        ref="video"
        playsinline
        :autoplay="autoplay"
        :muted="muted"
        :loop="loop"
        :class="isMobile && 'mobile'"
        poster="@/assets/images/promo-still-2.jpg"
        @click="clickVideo"
      >
        <source
          src="https://nodejsapi.inviplay.nl/api/attachments/inviplay-219209.appspot.com/download/promo-480.webm"
          type="video/webm"
        />
        <source
          src="https://nodejsapi.inviplay.nl/api/attachments/inviplay-219209.appspot.com/download/promo-480.mp4"
          type="video/mp4"
        />
      </video>
      <div
        v-if="showOverlay"
        class="video-overlay"
        :class="isMobile && 'mobile'"
      >
        <div class="play-button" @click="play">
          <i class="fal fa-play" />
        </div>
      </div>
      <div
        ref="topbar"
        class="flex items-center"
        :class="
          smallerHeader
            ? 'top-bar-container small h-12'
            : 'top-bar-container h-24'
        "
      >
        <div class="flex-1">
          <div class="logo">
            <img src="@/assets/images/logo.png" />
          </div>
        </div>
        <div class="">
          <button
            href="#"
            class="button button-secondary button-sm w-32 mr-4 p-2"
            @click="routeToRegisterPreferences"
          >
            aanmelden
          </button>
          <a href="#" class="link text-white" @click.prevent="routeToLogin">
            inloggen
          </a>
        </div>
      </div>
      <div class="controls">
        <div class="mute" @click="mute">
          <i v-if="muted" class="fal fa-volume-mute" />
          <i v-else class="fal fa-volume-up" />
        </div>
      </div>

      <!-- <div class="play">
          <i v-if="$refs.video && $refs.video.paused" class="fal fa-play" />
          <i v-else class="fal fa-pause" />
        </div> -->
    </div>
    <div class="container max-w-5xl">
      <h1
        class="font-bold text-green-600 text-center mt-6 mb-4 text-4xl sm:text-5xl lg:text-6xl"
      >
        It pays to play
      </h1>
      <div
        class="text-green-600 text-center text-lg sm:text-xl md:text-2xl font-normal sm:mt-6 md:mt-8 lg:mt-12"
      >
        Of je nu nieuwe mensen wil leren kennen, fit wil worden of de sport zo
        leuk vindt: samen sporten maakt het leven leuker.
      </div>

      <div class="flex items-center">
        <button
          class="button button-primary w-full sm:w-80 text-2xl h-14"
          @click="routeToRegisterPreferences"
        >
          Gratis aanmelden
        </button>
      </div>

      <!-- <div class="invite-play">Invite & play</div> -->
    </div>
    <div class="green-row">
      <div class="container max-w-5xl landing-flex position-relative">
        <div class="text">
          <div class="heading-1">aanmelden is gratis</div>
          <div class="subtitle-1">
            zo maken we sport toegankelijk voor iedereen!
          </div>
          <div class="heading-1">ontvang invites</div>
          <div class="subtitle-1">
            krijg uitnodigingen van jouw favoriete sportactiviteiten
          </div>
          <div class="heading-1">vind sporters</div>
          <div class="subtitle-1">
            organiseer een activiteit en vind extra spelers
          </div>
          <div class="heading-1">maak een groep</div>
          <div class="subtitle-1">
            hou eenvoudig een klassementje bij en handige aanwezigheidstool
          </div>
        </div>
        <div class="image">
          <img src="@/assets/images/screenshot-1.png" />
        </div>
      </div>
    </div>
    <div class="white-row">
      <div class="container max-w-5xl landing-flex position-relative">
        <div class="text">
          <div class="heading-1">ook voor verenigingen</div>
          <div class="subtitle-1">
            organiseer activiteiten voor leden & niet leden
          </div>
          <div class="heading-1">extra inkomsten</div>
          <div class="subtitle-1">
            betalende sporters die normaal niet op jullie club komen
          </div>
        </div>
        <div class="text">
          <div class="heading-1">aangepast lidmaatschap</div>
          <div class="subtitle-1">
            deelnemers bekijken per keer of ze tijd hebben en betalen per keer
          </div>
          <div class="heading-1">geen gedoe met betalingen</div>
          <div class="subtitle-1">
            deelnemers aan jullie activiteiten rekenen via iDeal af
          </div>
        </div>
      </div>
    </div>
    <div class="green-row">
      <div class="container max-w-5xl">
        <div class="title-white title-white-smaller">
          Ontdek de mogelijkheden van Inviplay!
        </div>
        <div class="flex items-center">
          <button
            class="button button-secondary w-full sm:w-80 text-2xl h-14"
            @click="routeToRegisterPreferences"
          >
            Start hier
          </button>
        </div>
        <div class="look-around">
          Eerst even rondkijken? klik dan
          <a
            class="link link-white link-bigger"
            href="#"
            @click.prevent="$router.push('/events')"
          >
            hier
          </a>
        </div>
        <div class="title-white">Invite & play!</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '../store'
import isMobile from '@/utils/isMobile'
import config from '@/utils/config'

export default {
  name: 'LandingPage',
  data() {
    return {
      muted: false,
      autoplay: false,
      showOverlay: true,
      smallerHeader: false,
      loop: false,
      creditcardsEnabled: config.featureFlags.creditcards,
      skipLandingPage: config.featureFlags.skipLandingPage
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.group.loading
    }),
    isMobile: function () {
      return isMobile()
    }
  },
  watch: {},
  created: function () {
    if (store.getters.isAuthenticated || config.featureFlags.skipLandingPage) {
      this.$router.push('/events')
    }
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    routeToRegisterPreferences: function () {
      this.$router.push('/register-preferences')
    },
    routeToLogin: function () {
      this.$router.push('/login')
    },
    clickVideo: function () {
      if (this.$refs.video) {
        if (!this.$refs.video.paused) {
          this.$refs.video.pause()
          this.showOverlay = true
        } else {
          this.$refs.video.play()
          this.showOverlay = false
        }
      }
    },
    mute: function () {
      if (this.$refs.video.muted) {
        this.$refs.video.volume = 0.5
        this.muted = false
      } else {
        this.$refs.video.volume = 0
        this.muted = true
      }
    },
    play: function () {
      this.$refs.video.play()
      this.showOverlay = false
    },
    handleScroll: function () {
      this.smallerHeader =
        (window.innerWidth < 480 && window.pageYOffset > 480) ||
        (window.innerWidth > 480 && window.pageYOffset > 640) ||
        (this.isMobile && window.pageYOffset > 300)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.landing-container {
  max-width: 960px;
}
.landing-flex {
  display: flex;
  @media (max-width: 480px) {
    display: block;
  }
}
.video-container {
  position: relative;
  width: 100%;
  height: 60vh;
  background-color: black;
  &.mobile {
    height: 50vh;
    @media screen and (orientation: landscape) {
      height: 100vh;
    }
  }
}
.controls {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: white;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 60vh;
  position: absolute;
  top: 0;
  left: 0;
  &.mobile {
    height: 50vh;
    @media screen and (orientation: landscape) {
      height: 100vh;
    }
  }
}

.video-overlay {
  object-fit: cover;
  width: 100vw;
  height: 60vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  &.mobile {
    height: 50vh;
    @media screen and (orientation: landscape) {
      height: 100vh;
    }
  }
}

.play-button {
  color: white;
  margin: auto;
  height: 4rem;
  width: 4rem;
  i {
    font-size: 4rem;
  }
}

.top-bar-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  position: fixed;
  transition: 0.2s;
  z-index: 9;
  &.small {
    background-color: $ipGreen;
    padding: 0.5rem;
    .logo {
      width: 5rem;
    }
  }
}

.btn-login {
  color: $ipGreen;
}
.left {
  flex: 1;
}
.mute {
  cursor: pointer;
}
.play-button {
}
.logo {
  width: 10rem;
  margin: 0;
  padding: 1rem 0;
  img {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 7rem;
  }
}
.landingpage-title {
  font-size: 4.5rem;
  font-weight: 700;
  text-align: center;
  color: $ipGreen;
  margin: 2rem 0 2rem;
  @media (max-width: 960px) {
    //lg
    font-size: 3.5rem;
  }
  @media (max-width: 768px) {
    // md
    font-size: 2.5rem;
  }
  @media (max-width: 480px) {
    // sm:font-size-2xl
    font-size: 2rem;
  }
}
.landingpage-subtitle {
  font-size: 1.5rem;
  font-weight: 400;
}
.button {
  width: 20rem;
  margin: 4rem auto;
  a {
    line-height: 3rem;
  }
  @media (max-width: 480px) {
    margin: 2rem auto;
  }
}
.button-bottom {
  margin: 1rem auto 3rem auto;
}
.look-around {
  font-size: 1.25rem;
  text-align: center;
  padding-bottom: 3rem;
}
.btn-landing {
  width: 100%;
  height: 4rem;
  font-size: 1.75rem;
  font-weight: 500;
  margin: 0 auto;
}
.green-row {
  background-color: $ipGreen;
  min-height: 15rem;
  width: 100%;
  color: white;
  padding: 2rem 0 0;
  @media (max-width: 480px) {
    padding-bottom: 2rem;
  }
}
.white-row {
  background-color: white;
  min-height: 15rem;
  width: 100%;
  color: $ipGreen;
  padding: 5rem 0 0;
  @media (max-width: 480px) {
    padding-top: 2rem;
  }
}
.text {
  width: 100%;
  padding: 0 1rem;
  @media (max-width: 960px) {
    max-width: 75%;
  }
  @media (max-width: 768px) {
    max-width: 50%;
  }
  @media (max-width: 480px) {
    max-width: 100%;
  }
}
.title-white {
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 2rem;
  text-align: center;
  color: white;
}
.title-white-smaller {
  font-size: 1.5rem;
  font-weight: 400;
}
.heading-1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.subtitle-1 {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 2rem;
}
.image {
  position: absolute;
  right: 0;
  bottom: -3rem;
  height: 32rem;
  img {
    height: 100%;
  }
  @media (max-width: 768px) {
    bottom: -2rem;
    height: 30rem;
  }
  @media (max-width: 480px) {
    display: none;
  }
}
.text-half {
  width: 50%;
  padding: 1rem 0;
}
.link-bigger {
  font-size: 1.25rem;
}
</style>
